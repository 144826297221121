// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';
import { Button } from 'react-bootstrap';

// Import local components
import HomePage from './HomePage';
import Footer from './footer/Footer';
import PrivacyPolicyPage from './footer/PrivacyPolicyPage';
import CookiePolicyPage from './footer/CookiePolicyPage';
import TermsAndConditionsPage from './footer/TermsAndConditionsPage';
import SignupWizard from './wizard/SignupWizard';
import RegistryPage from './pages/RegistryPage';
import GiftBitcoin from './pages/GiftBitcoin';
import { FloatingAssistant, VoiceAssistantProvider } from './FloatingAssistant';

/**
 * ScrollToTop component ensures that the page scrolls to the top on route changes.
 */
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

/**
 * Main App component
 */
function App() {
  // LiveKit configuration state
  const [livekitConfig, setLivekitConfig] = useState({ token: null, serverUrl: null });
  
  // State for controlling the floating button visibility
  const [showFloatingButton, setShowFloatingButton] = useState(true);

  // Fetch LiveKit token when component mounts
  useEffect(() => {
    async function fetchLivekitToken() {
      try {
        console.log('Attempting to fetch LiveKit token...');
        const response = await fetch('https://gfty.in/api/livekit-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            room: 'voice-assistant',
            username: 'user-' + Math.random().toString(36).substr(2, 9),
            metadata: JSON.stringify({
              name: 'Voice Assistant User'
            })
          })
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`Failed to fetch LiveKit token: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        setLivekitConfig({
          token: data.token,
          serverUrl: 'wss://piggybank-72822a0v.livekit.cloud'
        });
      } catch (error) {
        console.error('Error fetching LiveKit token:', error);
      }
    }

    fetchLivekitToken();
  }, []);

  // Handle navbar scroll effect
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.modern-navbar');
      if (window.scrollY > 50) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Route-based button visibility logic
  useEffect(() => {
    const hideButtonPaths = ['/signup', '/create-registry']; // Add paths where you want to hide the button
    const currentPath = window.location.pathname;
    const shouldShow = !hideButtonPaths.some(path => currentPath.startsWith(path));
    setShowFloatingButton(shouldShow);
  }, []);

  return (
    <VoiceAssistantProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar className="modern-navbar" expand="lg">
          <Container>
            <Navbar.Brand as={NavLink} to="/" className="brand-logo">
              <span className="brand-text">
                <span className="brand-first">TrustFund</span>
                <span className="brand-second">Baby</span>
              </span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Button 
                  as={NavLink} 
                  to="/signup" 
                  className="create-registry-btn"
                >
                  Create Registry
                </Button>
                <Button 
                  as={NavLink} 
                  to="/gift" 
                  className="gift-bitcoin-btn"
                >
                  Gift Bitcoin 🎁
                </Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {livekitConfig.token && (
          <FloatingAssistant 
            token={livekitConfig.token}
            serverUrl={livekitConfig.serverUrl}
            showFloatingButton={showFloatingButton}
          />
        )}

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignupWizard />} />
          <Route path="/registry/:registryId" element={<RegistryPage />} />
          <Route path="/gift" element={<GiftBitcoin />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/cookie-policy" element={<CookiePolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </VoiceAssistantProvider>
  );
}

export default App;
